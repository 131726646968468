import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Image from "../../../common/Image";
import CardSummary from "../blogNews/CardSummary";
import CardIcon from "../../common/CardIcon";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ALink from "../../../common/ALink";
import { Typography } from "@material-ui/core";
import { Container } from "@material-ui/core";
const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    tagName: {
        backgroundColor: "#CCC",
        display: "inline-block",
        borderRadius: "50px",
        padding: "8px 24px",
        marginTop: "12px",
        fontStyle: "italic",
        fontWeight: 400
    },
    left: {
        marginBottom: "15px",
        "@media screen and (max-width: 767px)": {
            marginBottom: "-30px",
        },
    },
    right: {
        paddingBottom: "15px",
        "@media screen and (max-width: 767px)": {
            marginBottom: "0px",
        },
    },
    img: {
        width: "100%",
        objectFit: "cover",
        height: "275px",
        "&:hover": {
            opacity: 0.8
        },
        "& img": {
            position: "static !important",
        },
        "@media screen and (max-width: 767px)": {
            height: "375px",
        },
        "@media screen and (max-width: 570px)": {
            height: "320px",
        },
        "@media screen and (max-width: 490px)": {
            height: "280px",
        },
        "@media screen and (max-width: 420px)": {
            height: "250px",
        },
        "@media screen and (max-width: 320px)": {
            height: "190px",
        },
        "@media screen and (min-width: 768px)": {
            paddingTop: "15%",
            paddingBottom: "15%"
        },
        "@media screen and (min-width: 900px)": {
            paddingTop: "5%",
            paddingBottom: "5%"
        },
        "@media screen and (min-width: 1280px)": {
            paddingTop: "0px",
            paddingBottom: "0px"
        }
    },
    grid: {
        "@media screen and (max-width: 767px)": {
            maxWidth: "none"
        },
    },
    titleLink: {
        textDecoration: "none"
    },
    title: {
        color: "#1f65b7",
        fontSize: "18px",
        textAlign: "justify"
    },
    iconButton: {
        "@media screen and (max-width: 767px)": {
            "& > div": {
                "&:before": {
                    display: "none"
                }
            }
        },
    },
    content: {
        padding: "20px",
        borderBottom: "solid 3px #DEDEDE ",
        "&:last-child": {
            border: "none"
        }
    },
    showInfo: {
        textAlign: "center",
        fontSize: "25px",
        margin: "70px 0",
    }

}));
function TagDetail({ data, tags }) {
    const classes = useStyles();
    const desktopViewport = useMediaQuery("(min-width:768px)");
    return (
        <Container>
            <Typography variant="h4" component="h1" className={classes.tagName}>
                {tags}
            </Typography>
            {
                data.length === 0 ?
                    <div className={classes.showInfo}> Không tìm thấy bài viết tương ứng </div>
                    :
                    <div className={classes.root}>
                        {data.map((item) => {
                            return (
                                <div className={classes.content}>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12} sm={5} container classes={{ item: classes.left }}>
                                            <ALink to={`/blog/${item.id}`}>
                                                <Image
                                                    className={classes.img}
                                                    {...item.img}
                                                />
                                            </ALink>
                                        </Grid>
                                        <Grid item xs={12} sm={7} container classes={{ item: classes.right }}>
                                            <Grid item xs container direction="column" spacing={2} className={classes.grid}>
                                                <Grid item xs>
                                                    {desktopViewport ? <CardSummary data={item} /> :
                                                        <ALink to={`/blog/${item.id}`} className={classes.titleLink}>
                                                            <Typography className={classes.title} variant="h5" component="h5">{item.news}</Typography>
                                                        </ALink>
                                                    }
                                                </Grid>
                                                <Grid item xs classes={{ item: classes.iconButton }}>
                                                    <CardIcon id={item.id} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            )
                        })}
                    </div>
            }

        </Container>
    );
}

export default TagDetail;