import React from "react"
import withRoot from "../../../components/shell/withRoot"
import SEO from "../../../components/seo"
import { graphql } from "gatsby"
import TagDetail from "../../../components/blog/sections/blogTags/TagDetail"

function TagDetailWrapper(props) {
  return (
    <React.StrictMode>
      <SEO title="Website học tiếng Nhật Miễn Phí" />
      <TagDetail
        data={props.data.allBlogJson.nodes}
        tags={props.pageContext.tags}
      />
    </React.StrictMode>
  )
}

export default withRoot(TagDetailWrapper)
export const query = graphql`
  query($tags: String) {
    allBlogJson(
      filter: { tags: { eq: $tags } }
      sort: { fields: order, order: DESC }
    ) {
      nodes {
        date
        tags
        title
        news
        id
        img {
          alt
          src {
            childImageSharp {
              fluid {
                srcSet
              }
            }
          }
        }
        summary
        content
        author
      }
    }
  }
`
